










import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const UIMutation = namespace('ui').Mutation

const AppProps = Vue.extend({
  props: {},
  created () {
    // @ts-ignore
  },
  methods: {
    redirectToHome() {
      this.$router.push('home')
    }
  }
})
@Component({
  components: {}
})
export default class NotFound extends AppProps {
  @UIMutation('setSimpleFooter') setSimpleFooter
}
